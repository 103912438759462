import { GetStaticProps } from "next";
import { VARIANT } from "../src/config";
import { getLanguageStaticProps } from "../src/helper/languageHelper";
import IndexComponent from "../src/pages/IndexComponent";
import { default as IndexComponentTv } from "../src/pages/gigatv/IndexComponent";
import { default as IndexComponentSplus3 } from "../src/pages/splus3/IndexComponent";

const Index: React.FC<{}> = () => {
  switch (VARIANT) {
    case "SPLUS":
    case "SCA":
      return (<IndexComponent />);
    case "SPLUS3":
      return (<IndexComponentSplus3 />);
    case "TV":
      return (<IndexComponentTv />);
  }
}

export const getStaticProps: GetStaticProps = (context) => {
  return getLanguageStaticProps(context);
}

export default Index;
